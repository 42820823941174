import clsx from "clsx";
import { Link } from "gatsby";
import React, { useCallback } from "react";
import { FaBook, FaCode, FaHome } from "react-icons/fa";
import { useDocsMarkdownPages } from "../../queries";
import SideMenuSection from "./SideMenuSection";

const MAIN_ITEMS = [
  {
    iconClassName: "text-white bg-gradient-to-br from-gray-400 to-gray-500",
    path: "/",
    title: "fabriqo.com",
    Icon: FaHome,
  },
  {
    iconClassName: "text-white bg-gradient-to-br from-teal-400 to-teal-500",
    path: "/docs",
    title: "Documentation",
    Icon: FaCode,
  },
  // {
  //   iconClassName: "text-white bg-gradient-to-br from-red-400 to-red-500",
  //   path: "/guides",
  //   title: "Guides",
  //   Icon: FaBook,
  // },
];

const MENU_GROUPS = [
  {
    title: "Blocks",
    groupSlug: "/docs/blocks/",
  },
  {
    title: "Platform",
    groupSlug: "/docs/platform/",
  },
];

const SideMenu = (props) => {
  const { navOpen } = props;

  const pagePath =
    typeof window !== "undefined" ? window.location.pathname : "";

  const pages = useDocsMarkdownPages();

  const renderMainItem = useCallback(
    (item, i) => {
      const { iconClassName, path, title, Icon } = item;

      return (
        <li key={i}>
          <Link
            className={clsx(
              "flex items-center transition-colors duration-150 text-sm",
              pagePath === path
                ? "text-gray-900 font-medium"
                : "text-coolGray-500 hover:text-gray-900"
            )}
            to={path}
          >
            <div
              className={clsx(
                iconClassName,
                "w-7 h-7 mr-3 rounded-md flex items-center justify-center"
              )}
            >
              <Icon className="text-xl" />
            </div>
            {title}
          </Link>
        </li>
      );
    },
    [pagePath]
  );

  const renderGroup = useCallback(
    (group, i) => {
      console.log("group", group, pages)
      const { groupSlug } = group;
      const groupPages = pages.filter(({ frontmatter: { slug } }) =>
        slug.includes(groupSlug)
      );

      return <SideMenuSection group={group} key={i} pages={groupPages} />;
    },
    [pages]
  );

  return (
    <aside
      className={clsx(
        "bg-white flex-shrink-0 h-full fixed w-full md:sticky top-16 md:top-20 bottom-0 md:overflow-y-visible md:w-64 md:block",
        navOpen ? "block" : "hidden"
      )}
    >
      <div className="h-full overflow-y-auto md:sticky md:overflow-hidden">
        <div className="p-6 md:p-8 md:overflow-y-auto border-b border-athens-gray md:border-b-0">
          <div className="space-y-8">
            <ul className="grid grid-cols-2 sm:grid-cols-3 grid-rows-2 gap-4 grid-flow-row md:block md:space-y-3 border-b border-athens-gray pb-8 text-gray-500">
              {MAIN_ITEMS.map(renderMainItem)}
            </ul>

            {MENU_GROUPS.map(renderGroup)}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideMenu;
