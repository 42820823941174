import React from "react";

import LayoutDocs from "../../components/LayoutDocs";
import SEO from "../../components/seo";

function DocsPage() {
  return (
    <LayoutDocs>
      <SEO keywords={[`fashion`, `tech`, `open-source`]} title="About" />

      <section className="flex flex-col items-center md:flex-row">
        <div>
          <h1 className="mb-8 text-4xl">Documentation</h1>

          <p className="mb-4 text-lg">
            Are you ready to launch your first Fabriqo project? Explore API and
            SDK documentation, and more.
          </p>

          <p className="mb-4">
            We aim to be the go to place for anyone interested in getting
            started building apps for the Fabriqo Block standard.
          </p>

          <p className="mb-4">
            It is also the easiest way to explore what apps and data libraries
            other members of the community have contributed with, both free and
            premium.
          </p>
        </div>
      </section>
    </LayoutDocs>
  );
}

export default DocsPage;
