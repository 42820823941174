import clsx from "clsx";
import { Link } from "gatsby";
import React, { useCallback } from "react";

const SideMenuSection = props => {
  const { group, pages } = props;
  const { title, groupSlug } = group;

  const renderSectionItem = useCallback((item, i) => {
    const { frontmatter: { title, slug } } = item;

    return (
      <li className="py-1" key={i}>
        <Link
          to={slug}
          className="relative leading-4 w-full hover:text-gray-900 !transition-colors !duration-150 text-sm items-center text-coolGray-500 group"
        >
          {title}
        </Link>
      </li>
    );
  }, []);

  return (
    <div className="space-y-2 text-center md:text-left">
      <h3 className="uppercase text-gray-400 text-lg tracking-wider">{title}</h3>
      <ul>
        {pages.map(renderSectionItem)}
      </ul>
    </div>
  );
};

export default SideMenuSection;
